/* UI Quote */

.ui-quote {
    margin-bottom: 70px;
    margin-left: 0px;
    border-top: 1px solid #ddd;
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: #eee;
    position: relative;
    padding-left: 70px;
}

.ui-quote quote {
    line-height: normal;
    font-size: 1rem;
    letter-spacing: .05em;
    font-family: $headings-font-family;
    line-height: 1;
}

.ui-quote:before {
    @extend %iconfont;
    content: "\f10d";
    font-size: 30px;
    position: absolute;
    top: -1px;
    left: 0;
    width: 45px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #fff;
    color: #999;
}

.ui-quote:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 25px 0 0;
    border-color: #FFFFFF transparent transparent transparent;
    position: absolute;
    top: -1px;
    left: 45px;
}

.ui-quote.with-image {

    padding: 0;
    padding-top: 30px;

    .page-history & {
        margin-bottom: 30px;
    }

    quote {
        line-height: 1.8;
    }

    .author {
        display: block;
        position: relative;
        font-style: normal;
        &:before {
            content: "\\\\\\\\";
            color: $brand-primary;
            font-size: 33px;
            letter-spacing: -5px;
            margin-right: 15px;
            vertical-align: top;
            margin-top: -4px;
            line-height: 1;
            font-weight: bold;
            font-family: "Times New Roman", "Bitstream Charter", Times, serif;
            float: left;
        }

        .title {
            font-style: italic;
        }

    }

    .author span {
        display: block;
        &:first-child {
            font-family: $headings-font-family;
            font-style: normal;
            letter-spacing: .05em;
            font-size: 1rem;
            color: $brand-primary;
            margin-top: 20px;
        }
    }
}
